import { createSlice, createSelector } from '@reduxjs/toolkit';

const userDataSlice = createSlice({
    name: 'userDataSliceName',
    initialState: {
        userName: "",
        userEmail: null,
        userToken: null,
        employee_id: null,
        refreshCalled: false,
        companyData: {
            company_code: null,
            company_name: null,
            base_url: null
        },
        isLoading: false,
        backDrop: false,
        lastLoginPage: {
            portal: '/auth',
            customerID: null,
            
        }
    },
    reducers: {
        startCompanyData(state, action) {
            state.isLoading = true;
        },
        setCompanyData(state, action) {
            state.companyData = action.payload;
        },
        startUserLogin(state, action) {
            state.isLoading = true;
        },
        setUserData(state, action) {
            if (action.payload.company_code) {
                state.userToken = action.payload.token;
                state.userName = action.payload.user.name;
                state.userEmail = action.payload.user.email;
            } else {
                state.userName = action.payload.data
            }

            //state.employee_id = 9;
            state.employee_id = 'admin';
            //localStorage.setItem("token", state.userToken);
        },
        startTogetRefreshToken(state, action) {
            state.isLoading = true;
        },
        startUserLogout(state, action) { },
        setNullAfterLogout(state, action) {
            state.userName = null;
            state.userEmail = null;
            state.userToken = 'logout';
        },
        setLastLoginPagePortale(state, action) {
            state.lastLoginPage = action.payload;
        },
        setTrueOnRefreshPage(state, action) {
            state.refreshCalled = true;
        },
        completedFetch(state, action) {
            state.isLoading = false;
        }
    }
});

export const {
    startUserLogin,
    startCompanyData,
    setCompanyData,
    setUserData,
    startUserLogout,
    setNullAfterLogout,
    setTrueOnRefreshPage,
    completedFetch,
    startTogetRefreshToken
} = userDataSlice.actions;

export default userDataSlice.reducer;

///////////////////////////////////////////////////////////////
///selector
const mainUserState = (state) => state.UserData;
//
export const isLoadingSelectorUser = createSelector([mainUserState], (UserDataState) => UserDataState.isLoading);
//
export const usersDataSelector = createSelector([mainUserState], (UserDataState) => {
    return {
        userName: UserDataState.userName,
        userEmail: UserDataState.userEmail,
        userToken: UserDataState.userToken,
        employeeId: UserDataState.employee_id
    };
});
//
export const companyDataSelector = createSelector([mainUserState], (UserDataState) => {
    return UserDataState.companyData;
});
//
export const refreshCalledSelector = createSelector([mainUserState], (UserDataState) => UserDataState.refreshCalled);
//
export const lastLoginPageSelector = createSelector([mainUserState], (userDataState) => userDataState.lastLoginPage);

export const wrongpassMessage = createSelector([mainUserState], (userDataState) => userDataState.message);