import { takeLatest, all, call, put } from "redux-saga/effects";
import {
  startCountry,
  startCountryById,
  setAllCountry,
  setAllCountry1,
  setSingleCountry,
  startAddEditNewCountry,
  successOfAddEdit,
  setErrorOfAddEdit,
  startDeleteCountry,
  completedFetch
} from "./countryDataSlice.selector";
///API's
import {
  getCountryMasterData,
  getCountryMasterData1,
  deleteCountryMasterData,
  addCountryMasterData,
  getCountryMasterDataById,
} from "../../Api/CountryMasterAPI/countryMasterAPI";
import { setNotificationAction } from "../NotificationStore/notification.slice.selector";
////////////////
////(1) Watcher for All Details
function* startAllCountry() {
  yield takeLatest(startCountry.type, setAllCountryData);
}
function* startAllCountry1() {
  yield takeLatest(startCountry.type, setAllCountryData1);
}
//(1) Next
// New next function for getCountryMasterData1
function* setAllCountryData1() {
  try {
    const data = yield call(getCountryMasterData1);
    // Handle data for getCountryMasterData1
    yield put(setAllCountry1(data));
    yield put(completedFetch());
  } catch (error) {
    // Handle error for getCountryMasterData1
    yield put(setErrorOfAddEdit(error.message));
  }
}
function* setAllCountryData() {
  try {
    const data = yield call(getCountryMasterData);
    yield put(setAllCountry(data));
    yield put(completedFetch());
  } catch (error) {
    yield put(setErrorOfAddEdit(error.message));
  }
}
//(2)Watcher
function* startAddDetails() {
  yield takeLatest(startAddEditNewCountry.type, setupAddDetails);
}
//(2)Next
function* setupAddDetails({ payload }) {
  try {
    const result = yield call(addCountryMasterData, payload);
    yield put(successOfAddEdit());
    if (result.status === 200 && result?.data.info === "") {
      yield put(
        setNotificationAction({
          message: "Added Successfully",
          variant: "success",
        })
      );
    } else if (result.status === 200) {
      yield put(
        setNotificationAction({
          message: "Edited Successfully",
          variant: "success",
        })
      );
    } else {
      yield put(
        setNotificationAction({
          message: "Country already exist",
          variant: "error",
        })
      );
    }
  } catch (error) {
    yield put(setErrorOfAddEdit(error.message));
    yield put(
      setNotificationAction({
        message: error.message,
        variant: "error",
      })
    );
  }
}
//(3)
function* startSingleCountryDataMaster() {
  yield takeLatest(startCountryById.type, setSingleDataDelete);
}
//(3) Next
function* setSingleDataDelete({ payload }) {
  try {
    const data = yield call(getCountryMasterDataById, payload);
    yield put(setSingleCountry(data));
    yield put(completedFetch());
  } catch (error) {
    yield put(setErrorOfAddEdit(error.message));
  }
}
//(4)Delete Watcher
function* startSingleCountryDelete() {
  yield takeLatest(startDeleteCountry.type, setLookupMaster);
}
//(4) Next
function* setLookupMaster({ payload }) {
  try {
    const resp = yield call(deleteCountryMasterData, payload);
    yield put(successOfAddEdit());
    yield put(
      setNotificationAction({
        message: "Successfully Deleted",
        variant: "error",
      })
    );
  } catch (error) {
    yield put(setErrorOfAddEdit(error.message));
    yield put(
      setNotificationAction({
        message: error.message,
        variant: "error",
      })
    );
  }
}


///Accumulator
export default function* countryDataSaga() {
  yield all([
    call(startAllCountry),
    call(startAllCountry1),
    call(startAddDetails),
    call(startSingleCountryDataMaster),
    call(startSingleCountryDelete),
  ]);
}
