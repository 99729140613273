import { takeLatest, all, call, put } from "redux-saga/effects";
import {
  startAllVendorsData,
  startVendorsDataByPageIndex,
  startFilterVendorsData,
  setAllVendorsData,
  setVendorListWithPage,
  completedFetch,
  startSingleVendorData,
  setSingleVendorData,
  startAddOrEditVendor,
  startDeleteVendor,
  startAddRestoreVendor,
  startAllStates,
  setAllStates,
  startViewVendorsData,
  setVendorViewExpenseList
} from "./vendor.data.slice.selector";
import { setFormNavigate } from "../formnavigation/formnavigation";

//API's
import {
  vendorData,
  vendorDataByPageIndex,
  singleVendorData,
  addOrEditVendorData,
  deleteVendorData,
  vendorFilterData,
  vendorState,
  viewVendorData,
} from "../../Api/VendorAPI/vendorAPI";
import { setNotificationAction } from "../NotificationStore/notification.slice.selector";
import { changeRecordData } from "../../Api/api";

//(1) Watcher
function* startAllVendorData() {
  yield takeLatest(startAllVendorsData.type, setAllVendorData);
}

//(1) Next
function* setAllVendorData({ payload }) {
  try {
    let allVendorDatas;      // variables banaya hai bas thats it
      allVendorDatas = yield call(vendorData, payload);
      yield put(setAllVendorsData(allVendorDatas));
    yield put(completedFetch());

  } catch (error) {
    yield put(
      setNotificationAction({
        message: error.message,
        variant: "error",
      })
    );
    yield put(completedFetch());
  }
}

////(1.1) With Pagination
function* startVendorDataByPageIndex() {
  yield takeLatest(startVendorsDataByPageIndex.type, setPaginationVendorAllData);
}

//(1.1) Next
function* setPaginationVendorAllData() {
  try {
    let allVendorDatas; 
    allVendorDatas = yield call(vendorDataByPageIndex); 
    yield put(setVendorListWithPage(allVendorDatas));
    yield put(completedFetch());

  } catch (error) {
    yield put(
      setNotificationAction({
        message: error.message,
        variant: "error",
      })
    );
    yield put(completedFetch());
  }
}

function* startVendorState() {
  yield takeLatest(startAllStates.type, setAllVendorStates);
}

//(1) Next
function* setAllVendorStates() {
  try {
    let allVendorDatas;      // variables banaya hai bas thats it   
      allVendorDatas = yield call(vendorState);
      yield put(setAllStates(allVendorDatas));
    yield put(completedFetch());

  } catch (error) {
    yield put(
      setNotificationAction({
        message: error.message,
        variant: "error",
      })
    );
    yield put(completedFetch());
  }
}

//(1.1) filter 
function* startFilterVendorData() {
  yield takeLatest(startFilterVendorsData.type, setAllVendorFilterData);
}
//(1.1) Next
function* setAllVendorFilterData({ payload }) {
  try {
    let allVendorDatas;
      allVendorDatas = yield call(vendorFilterData, payload);
      yield put(setVendorListWithPage(allVendorDatas));
    yield put(completedFetch());

  } catch (error) {
    yield put(
      setNotificationAction({
        message: error.message,
        variant: "error",
      })
    );
    yield put(completedFetch());
  }
}

//(2) Watcher
function* startSingleVendData() {
  yield takeLatest(startSingleVendorData.type, setSingleData);
}
//(2) Next
function* setSingleData({ payload }) {
  try {
    const data = yield call(singleVendorData, payload);

    yield put(setSingleVendorData(data));
    yield put(completedFetch());
  } catch (error) {
    yield put(
      setNotificationAction({
        message: error.message,
        variant: "error",
      })
    );
    yield put(completedFetch());
  }
}
//(3) Watcher
function* startAddEditVendorWatcher() {
  yield takeLatest(startAddOrEditVendor.type, setupVendorDataAddEdit);
}
//(3) Next
function* setupVendorDataAddEdit({ payload }) {
  try {
    yield call(addOrEditVendorData, payload);

    yield put(setFormNavigate(true));
    yield put(
      setNotificationAction({
        message: "Successfully Updated",
        variant: "success",
      })
    );
    yield put(completedFetch());
  } catch (error) {
    yield put(
      setNotificationAction({
        message: error.message,
        variant: "error",
      })
    );
    yield put(completedFetch());
  }
}
//(4)Watcher
function* startVendorDelete() {
  yield takeLatest(startDeleteVendor.type, setUpForDelete);
}
//(4)
function* setUpForDelete({ payload }) {
  try {
    yield call(deleteVendorData, payload.data);
    yield put(
      setNotificationAction({
        message: "Successfully Deleted",
        variant: "success",
      })
    );

    yield put(startVendorsDataByPageIndex(payload.tableData));
    yield put(completedFetch());
  } catch (error) {
    yield put(
      setNotificationAction({
        message: error.message,
        variant: "error",
      })
    );
    yield put(completedFetch());
  }
}

//(5) Watcher For Add Client Restore
function* start_startAddRestoreVendor() {
  yield takeLatest(startAddRestoreVendor.type, set_startAddRestoreVendor);
}
//(5)Next
function* set_startAddRestoreVendor({ payload }) {
  try {
    yield call(changeRecordData, payload);
    yield put(startVendorsDataByPageIndex(payload.tableData));
    yield put(
      setNotificationAction({
        message: "Successfully Restored",
        variant: "success",
      })
    );
    yield put(completedFetch());
  } catch (error) {
    yield put(
      setNotificationAction({
        message: error.message,
        variant: "error",
      })
    );
    yield put(completedFetch());
  }
}

//(6) View expense data 
function* startViewVendorExpense() {
  yield takeLatest(startViewVendorsData.type, setAllVendorexpenseData);
}
//(6) Next
function* setAllVendorexpenseData({ payload }) {
  try {
    let allVendorExpenseDatas;
    allVendorExpenseDatas = yield call(viewVendorData, payload);
    yield put(setVendorViewExpenseList(allVendorExpenseDatas));
    yield put(completedFetch());

  } catch (error) {
    yield put(
      setNotificationAction({
        message: error.message,
        variant: "error",
      })
    );
    yield put(completedFetch());
  }
}


//Accumulator
export default function* vendorsDataSaga() {
  yield all([
    call(startAllVendorData),
    call(startVendorDataByPageIndex),
    call(startVendorState),
    call(startFilterVendorData),
    call(startSingleVendData),
    call(startAddEditVendorWatcher),
    call(startVendorDelete),
    call(start_startAddRestoreVendor),
    call(startViewVendorExpense),
  ]);
}