import { takeLatest, all, call, put } from "redux-saga/effects";
import {
  startAllClientsData,
  startClientsDataByPageIndex,
  startFilterClientsData,
  setAllClientsData,
  setClientListWithPage,
  completedFetch,
  startSingleClientData,
  setSingleClientData,
  startAddOrEditClient,
  startDeleteClient,
  startAddRestoreClient,
  setFilterData,
  startFilterData
} from "./client.data.slice.selector";
import { setFormNavigate } from "../formnavigation/formnavigation";

//API's
import {
  clientData,
  clientDataByPageIndex,
  singleClientData,
  addOrEditClientData,
  deleteClientData,
  clientFilterData,
} from "../../Api/ClientsAPI/clientAPI";
import { setNotificationAction } from "../NotificationStore/notification.slice.selector";
import { changeRecordData } from "../../Api/api";

//(1) Watcher
function* startAllClientData() {
  yield takeLatest(startAllClientsData.type, setAllData);
}

//(1) Next
function* setAllData({ payload }) {
  try {
    let allClientDatas;
      allClientDatas = yield call(clientData, payload);
      yield put(setAllClientsData(allClientDatas));
    yield put(completedFetch());

  } catch (error) {
    yield put(
      setNotificationAction({
        message: error.message,
        variant: "error",
      })
    );
    yield put(completedFetch());
  }
}

////(1.1) With Pagination
function* startClientDataByPageIndex() {
  yield takeLatest(startClientsDataByPageIndex.type, setPaginationAllData);
}

//(1.1) Next
function* setPaginationAllData() {
  try {
    let allClientDatas;
    allClientDatas = yield call(clientDataByPageIndex);
    yield put(setClientListWithPage(allClientDatas));
    yield put(completedFetch());

  } catch (error) {
    yield put(
      setNotificationAction({
        message: error.message,
        variant: "error",
      })
    );
    yield put(completedFetch());
  }
}


//(1.2) filter 
function* startFilterClientData() {
  yield takeLatest(startFilterClientsData.type, setAllFilterData);
}
//(1.2) Next
function* setAllFilterData({ payload }) {
  try {
    let allClientDatas;
      allClientDatas = yield call(clientFilterData, payload);
      yield put(setClientListWithPage(allClientDatas));
    // }
    yield put(completedFetch());

  } catch (error) {
    yield put(
      setNotificationAction({
        message: error.message,
        variant: "error",
      })
    );
    yield put(completedFetch());
  }
}

//(2) Watcher
function* startSingleData() {
  yield takeLatest(startSingleClientData.type, setSingleData);
}
//(2) Next
function* setSingleData({ payload }) {
  try {
    const data = yield call(singleClientData, payload);

    yield put(setSingleClientData(data));
    yield put(completedFetch());
  } catch (error) {
    yield put(
      setNotificationAction({
        message: error.message,
        variant: "error",
      })
    );
    yield put(completedFetch());
  }
}
//(3) Watcher
function* startAddEditClientWatcher() {
  yield takeLatest(startAddOrEditClient.type, setupClientDataAddEdit);
}
//(3) Next
function* setupClientDataAddEdit({ payload }) {
  try {
    yield call(addOrEditClientData, payload);

    yield put(setFormNavigate(true));
    yield put(
      setNotificationAction({
        message: "Successfully Updated",
        variant: "success",
      })
    );
    yield put(completedFetch());
  } catch (error) {
    yield put(
      setNotificationAction({
        message: error.message,
        variant: "error",
      })
    );
    yield put(completedFetch());
  }
}
//(4)Watcher
function* startClientDelete() {
  yield takeLatest(startDeleteClient.type, setUpForDelete);
}
//(4)
function* setUpForDelete({ payload }) {
  try {
    yield call(deleteClientData, payload.data);
    yield put(
      setNotificationAction({
        message: "Successfully Deleted",
        variant: "success",
      })
    );

    yield put(startClientsDataByPageIndex(payload.tableData));
    yield put(completedFetch());
  } catch (error) {
    yield put(
      setNotificationAction({
        message: error.message,
        variant: "error",
      })
    );
    yield put(completedFetch());
  }
}

//(5) Watcher For Add Client Restore
function* start_startAddRestoreClient() {
  yield takeLatest(startAddRestoreClient.type, set_startAddRestoreClient);
}
//(5)Next
function* set_startAddRestoreClient({ payload }) {
  try {
    yield call(changeRecordData, payload);
    yield put(startClientsDataByPageIndex(payload.tableData));
    yield put(
      setNotificationAction({
        message: "Successfully Restored",
        variant: "success",
      })
    );
    yield put(completedFetch());
  } catch (error) {
    yield put(
      setNotificationAction({
        message: error.message,
        variant: "error",
      })
    );
    yield put(completedFetch());
  }
}
//(6) Watcher
function* startFilData() {
  yield takeLatest(startFilterData.type, setFilsData);
}
//(6) Next
function* setFilsData({ payload }) {
  try {
    yield put(setFilterData(payload));
    yield put(completedFetch());
  } catch (error) {
    yield put(
      setNotificationAction({
        message: error.message,
        variant: "error",
      })
    );
    yield put(completedFetch());
  }
}

//Accumulator
export default function* clientsDataSaga() {
  yield all([
    call(startAllClientData),
    call(startClientDataByPageIndex),
    call(startFilterClientData),
    call(startSingleData),
    call(startAddEditClientWatcher),
    call(startClientDelete),
    call(start_startAddRestoreClient),
    call(startFilData)
  ]);
}
