import { createSlice } from "@reduxjs/toolkit";
import { createSelector } from "reselect";

const payrollsSliceSelector = createSlice({
  name: "payrollsSliceSelector",
  initialState: {
    isLoading: false,
    allEmployeesPayrollsCTC: [],
    singleEmployeePayrollsCTC: {},
    singleEmployeeAllCTCPayrolls: [],
    allPayrollMaster: [],
    allEmployeesGeneratePayrollDetails: [],
    monthlyPaymentDetails: {
      singlePayrollMasterRes: {},
      payrollDetailsByPayrollMasterId: [],
    },
    singleEmployeePayrollDetail: {},
    masterId: null,
    backDrop: false,
    snackbarSuccess: false,
    snackbarError: false,
    errorMessage: "",
    pageIndex: 1,
    pageSize: 10,
  },
  reducers: {
    setPage(state, action) {
      state.pageIndex = action.payload;
    },
    setRowsPerPage(state, action) {
      state.pageSize = action.payload;
    },
    startAllEmployeesPayrollsCTC(state, action) {
      state.isLoading = true;
    },
    startSingleEmployeePayrollCTC_ById(state, action) {
      state.isLoading = true;
    },
    startAllPayrollMaster(state, action) {
      state.isLoading = true;
    },
    startMaonthlyPaymentDetails_ByPayrollMasterId(state, action) {
      state.isLoading = true;
    },
    startAllEmployeesGeneratePayrollDetails(state, action) {
      state.isLoading = true;
    },
    startSingleEmployeePayrollDetails(state, action) {
      state.isLoading = true;
    },
    startSingleEmployeeAllPayrollCTC_EmployeeId(state, action) {
      state.isLoading = true;
    },
    startSingleEmployeeDraftSalary(state , action){
      state.isLoading = true;
    },
    setAllEmployeesPayrollsCTC(state, action) {
      state.allEmployeesPayrollsCTC = action.payload;
    },
    setSingleEmployeePayrollCTC_ById(state, action) {
      if (action.payload) {
        state.singleEmployeePayrollsCTC = action.payload;
      }
    },
    setAllPayrollMaster(state, action) {
      state.allPayrollMaster = action.payload;
    },
    setMaonthlyPaymentDetails_ByPayrollMasterId(state, action) {
      state.monthlyPaymentDetails = action.payload;
    },
    setAllEmployeesGeneratePayrollDetails(state, action) {
      state.allEmployeesGeneratePayrollDetails = action.payload;
    },
    setSingleEmployeePayrollDetails(state, action) {
      state.singleEmployeePayrollDetail = action.payload;
    },
    setSingleEmployeeAllCTCPayroll_ByEmployeeId(state, action) {
      state.singleEmployeeAllCTCPayrolls = action.payload;
    },
    successOfAddEdit(state, action) {
      state.backDrop = false;
      state.snackbarSuccess = true;
    },
    setErrorOfAddEdit(state, action) {
      state.backDrop = false;
      state.snackbarError = true;
      state.errorMessage = action.payload;
    },
    resetSnackbar(state, action) {
      state.snackbarSuccess = false;
      state.snackbarError = false;
      state.errorMessage = "";
    },
    startAddEmployeePayrollCTC(state, action) {
      state.backDrop = true;
    },
    startAddSinglePayrollDetail(state, action) {
      state.backDrop = true;
    },
    startAddMsterId(state, action) {
      state.backDrop = true;
    },
    startAddEditPaymentStatus(state, action) {
      state.backDrop = true;
    },
    startDeleteEmployeePayrollCTC(state, action) {
      state.backDrop = true;
    },
    completedFetch(state, action) {
      state.isLoading = false;
    },
    setMasterId(state, action) {
      state.masterId = action.payload;
    },
  },
});
///
export const {
  setSingleEmployeePayrollDetails,
  setAllEmployeesGeneratePayrollDetails,
  setMaonthlyPaymentDetails_ByPayrollMasterId,
  setAllPayrollMaster,
  setSingleEmployeePayrollCTC_ById,
  setAllEmployeesPayrollsCTC,
  startSingleEmployeePayrollDetails,
  startAllEmployeesGeneratePayrollDetails,
  startMaonthlyPaymentDetails_ByPayrollMasterId,
  startAllPayrollMaster,
  startSingleEmployeePayrollCTC_ById,
  startAllEmployeesPayrollsCTC,
  setRowsPerPage,
  setPage,
  startSingleEmployeeDraftSalary,
  successOfAddEdit,
  setErrorOfAddEdit,
  resetSnackbar,
  startAddEmployeePayrollCTC,
  startAddSinglePayrollDetail,
  startAddMsterId,
  startAddEditPaymentStatus,
  startDeleteEmployeePayrollCTC,
  completedFetch,
  setSingleEmployeeAllCTCPayroll_ByEmployeeId,
  startSingleEmployeeAllPayrollCTC_EmployeeId,
  setMasterId,
} = payrollsSliceSelector.actions;

export default payrollsSliceSelector.reducer;

/////Selectors
const mainPayrollDataState = (state) => state.PayrollData;
//
export const isLoadingSelector = createSelector(
  [mainPayrollDataState],
  (payrollState) => payrollState.isLoading
);
//
export const allEmployeesPayrollsCTCSelector = createSelector(
  [mainPayrollDataState],
  (payrollState) => payrollState.allEmployeesPayrollsCTC
);
//
export const allEmployeesGeneratePayrollDetailsSelector = createSelector(
  [mainPayrollDataState],
  (payrollState) => payrollState.allEmployeesGeneratePayrollDetails
);
//
export const singleEmployeePayrollsCTCSelector = createSelector(
  [mainPayrollDataState],
  (payrollState) => payrollState.singleEmployeePayrollsCTC
);
//
export const singleEmployeeAllCTCPayrollsSelector = createSelector(
  [mainPayrollDataState],
  (payrollState) => payrollState.singleEmployeeAllCTCPayrolls
);
//
export const allPayrollMasterSelector = createSelector(
  [mainPayrollDataState],
  (payrollState) => payrollState.allPayrollMaster
);
//
export const monthlyPaymentDetailsSelector = createSelector(
  [mainPayrollDataState],
  (payrollState) => payrollState.monthlyPaymentDetails
);
//
export const singleEmployeePayrollDetailSelector = createSelector(
  [mainPayrollDataState],
  (payrollState) => payrollState.singleEmployeePayrollDetail
);
//
export const notificationSelector = createSelector(
  [mainPayrollDataState],
  (payrollState) => {
    return {
      backDrop: payrollState.backDrop,
      snackbarSuccess: payrollState.snackbarSuccess,
      snackbarError: payrollState.snackbarError,
      errorMessage: payrollState.errorMessage,
    };
  }
);
export const masterIdForNavigateSelector = createSelector(
  [mainPayrollDataState],
  (payrollState) => payrollState.masterId
);
export const pageIndexSelector = createSelector(
  [mainPayrollDataState],
  (payrollState) => payrollState.pageIndex
);
export const pageSizeSelector = createSelector(
  [mainPayrollDataState],
  (payrollState) => payrollState.pageSize
);
