import { newAPI } from "../newAxios";

export const getLookUpDetailsData = async (data) => {
  const res = await newAPI.post("lookupDetails", data);
  return res.data;
};

export const addLookUpDetails = async (data) => {
  const result = await newAPI.post("lookupDetails/add", data);
  return result;
};

export const getLookupMasterData = async () => {
  const res = await newAPI.get("lookupDetails/lookup-types");
  return res.data;
};

export const GetLookUpDetailsDataByLookupId = async (data) => {
  const res = await newAPI.get(`lookupDetails/lookup_id/${data}`);
  return res.data;
};
export const GetLookUpDetailsDataByLookupIdAndPagination = async (data) => {
 
  const res = await newAPI.post(`lookupDetails/lookup_id/${data.id}`, data);
  return res.data;
};

export const deleteLookupDetailsData = async (id) => {
  const result = await newAPI.delete(`lookupDetails/${id}`);

  return result;
};
